<template>
  <header class="section">
    <div class="container">
      <h1 class="title is-1 has-text-centered">
        <img alt="Bingo Bango logo" src="../assets/logo.svg" width="800" />
      </h1>

      <div class="buttons is-centered">
        <button class="button is-warning is-large" @click="startGame">
          <span>Start</span>
          <span class="icon"><font-awesome-icon icon="hand-point-right"/></span>
        </button>
      </div>

      <h3 class="subtitle is-4">Happy Christmas Luka &amp; Sia</h3>
      <p class="mb-3">
        As well as this special Bingo game made just for you. There are 4 hidden
        digital treats, can you find them all? Let's Bingo Bango!!!
      </p>

      <Eggs />

      <Tree />
    </div>
  </header>

  <Snow />
</template>

<script>
import { customAlphabet } from 'nanoid'
import { lowercase, numbers } from 'nanoid-dictionary'
import Eggs from '../shared/Eggs'
import Snow from '../shared/Snow'
import Tree from '../shared/Tree'

const Home = {
  components: {
    Eggs,
    Snow,
    Tree,
  },
  methods: {
    startGame() {
      const nanoid = customAlphabet(lowercase + numbers, 6)
      this.$router.push({
        name: 'Game',
        params: { id: nanoid() },
      })
    },
  },
}

// https://next.router.vuejs.org/guide/advanced/transitions.html#per-route-transition
export default Home
</script>
