<template>
  <div class="container has-text-centered">
    <div v-if="!book">
      <img alt="Creepy Santa" src="../assets/creepy-santa.jpg" />

      <h1 class="is-size-4 has-text-centered mt-1 mb-2">
        Have you been a good boy or girl?
      </h1>

      <div class="buttons is-centered">
        <button class="button is-medium is-success" @click="$router.back()">
          yes
        </button>
        <button class="button is-medium is-danger" @click="no">no</button>
      </div>
    </div>
  </div>
  <div v-if="book">
    <img alt="Brown book" src="../assets/brown-book.jpg" />
    <h1 class="is-size-4 has-text-centered mt-1 mb-2">
      Writing your name in <font-awesome-icon icon="hand-point-up" /><br />
      No <font-awesome-icon icon="gifts" />'s' for you.
    </h1>

    <div class="buttons is-centered">
      <button class="button is-medium is-success" @click="$router.back()">
        bye
      </button>
    </div>
  </div>
  <EggAnnouncement v-if="announce" @enough="announce = false" />
</template>

<script>
import EggAnnouncement from '../shared/EggAnnouncement'

import { foundEgg, isFound } from '../shared/eggs'

const Santa = {
  components: {
    EggAnnouncement,
  },
  data() {
    return { book: false, announce: false }
  },
  methods: {
    no() {
      this.book = true
      setTimeout(() => {
        this.announce = !isFound('book')
        foundEgg('book')
      }, 2000)
    },
  },
}

export default Santa
</script>

<style scoped>
img {
  height: 80vmax;
}
</style>
