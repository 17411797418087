<template>
  <div>
    <div class="egg" :class="{ 'egg--found': eggs.globe }">
      <font-awesome-icon icon="globe" />
    </div>
    <div class="egg" :class="{ 'egg--found': eggs.tree }">
      <font-awesome-icon icon="tree" />
    </div>
    <div class="egg" :class="{ 'egg--found': eggs.fourofour }">
      <font-awesome-icon icon="skull" />
    </div>
    <div class="egg" :class="{ 'egg--found': eggs.book }">
      <font-awesome-icon icon="book" />
    </div>
  </div>
</template>

<script>
import { getEggs } from './eggs'

const Eggs = {
  data() {
    return {
      eggs: getEggs(),
    }
  },
}

export default Eggs
</script>

<style scoped>
.egg {
  color: #666;
  width: 2rem;
  height: 2.2rem;
  margin: 0.2rem;
  display: inline-block;
  background-color: #222;
  border-color: transparent;
  text-align: center;
  padding-top: 0.4em;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
}

.egg--found {
  background-color: #be9400;
  color: yellow;
}
</style>
