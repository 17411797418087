<template>
  <button
    @click="callNumber(number.value)"
    class="button"
    :class="{
      'is-info ': !number.calledAt,
      'is-outlined': !number.calledAt,
      'is-danger': number.calledAt,
      animate__animated: fall,
      animate__hinge: fall,
      animate__animated: number.calledAt,
      animate__rubberBand: number.calledAt,
    }"
    :style="{
      '--animate-delay': animationdelay,
      '--animate-duration': animationSpeed,
    }"
  >
    {{ number.value }}
  </button>
</template>

<script>
const Number = {
  props: { number: Object, fall: Boolean },
  emits: ['number-called'],
  methods: {
    callNumber(number) {
      this.$emit('number-called', number)
    },
  },
  watch: {
    fall: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.animationSpeed = `${Math.floor(Math.random() * 3)}s`
          this.animationdelay = `${Math.floor(Math.random() * 3)}s`
        } else {
          this.animationSpeed = '1s'
          this.animationdelay = '1s'
        }
      },
    },
  },
}

export default Number
</script>

<style scoped>
button {
  z-index: 10001;
  position: relative;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  margin: 0.2rem;
}
</style>
