import { reactive, watch } from 'vue'

const STORAGE_KEY = 'bing-bango-banko'

const initialState = {
  games: {},
  settings: {
    currentVoice: null,
    muted: false,
  },
  eggs: {
    tree: false,
    fourofour: false,
    book: false,
    globe: false,
  },
}

export let appState = reactive(initialState)

export const initialise = () => {
  const stateString = localStorage.getItem(STORAGE_KEY)
  if (stateString) {
    appState = reactive(JSON.parse(stateString))
  }

  watch(appState, next => {
    saveState(next)
  })
}

const saveState = newState => {
  console.log('saving state', newState)
  localStorage.setItem(STORAGE_KEY, JSON.stringify(newState))
}
