import { appState } from '../shared/store'

export const load = id => {
  const game = appState.games[id]
  if (game) {
    return game
  } else {
    appState.games[id] = create()
    return appState.games[id]
  }
}

export const save = (id, numbers) => {
  load(id).numbers = numbers
}

export const create = (count = 90) => {
  return [...Array(count).keys()].map(index => ({
    value: index + 1,
  }))
}

//  TODO: realtime https://pusher.com/docs/channels/using_channels/events#triggering-client-events
