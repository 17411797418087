<template>
  <div class="game-history">
    <div class="game-history__right-trim">
      <div
        class="history-number is-size-7"
        v-for="number in history"
        :key="number.value"
      >
        {{ number.value }}
      </div>
    </div>
  </div>
</template>

<script>
const History = {
  props: { history: Array },
}

export default History
</script>

<style scoped>
.game-history {
  display: inline-block;
  height: 2.1rem;
  white-space: nowrap;
  overflow: hidden;
  max-width: 105px;
}

.game-history__right-trim {
  float: right;
}

@media only screen and (min-width: 768px) {
  .game-history {
    max-width: 210px;
  }
}

@media only screen and (min-width: 1024px) {
  .game-history {
    max-width: 350px;
  }
}

.history-number {
  color: #666;
  border-radius: 50%;
  width: 2rem;
  margin: 0.1rem;
  display: inline-block;
  background-color: #222;
  text-align: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
</style>
