import { createWebHistory, createRouter } from 'vue-router'

import Home from './pages/Home'
import Santa from './pages/Santa'
import Globe from './pages/Globe'

import Game from './game/Game'
import Join from './game/Join'

import NotFound from './pages/NotFound'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/games/:id',
    name: 'Game',
    component: Game,
    props: true,
  },
  {
    path: '/join/:id',
    name: 'Join',
    component: Join,
    props: true,
  },
  {
    path: '/santa',
    name: 'Santa',
    component: Santa,
  },
  {
    path: '/globe',
    name: 'Globe',
    component: Globe,
  },
  { path: '/:catchAll(.*)', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
