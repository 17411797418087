<template>
  <div class="box is-flex is-justify-content-center is-align-items-center">
    <div id="globe" @click="shakeGlobe">
      <canvas id="town"></canvas>
      <canvas id="snowFlakes"></canvas>
    </div>
  </div>
  <EggAnnouncement v-if="announce" @enough="announce = false" />
</template>

<script>
import gsap from 'gsap'
import EggAnnouncement from '../shared/EggAnnouncement'
import { foundEgg, isFound } from '../shared/eggs'

function makeGlobe(canvas) {
  var ctx = canvas.getContext('2d'),
    data,
    canvas2,
    ctx2,
    rafAnim = null
  canvas.width = canvas.offsetWidth
  canvas.height = canvas.offsetWidth

  const amount = 1000

  var img = new Image()
  img.onload = function() {
    ctx.drawImage(img, 0, 0, 800, 800, 0, 0, canvas.width, canvas.height)
    data = ctx.getImageData(0, 0, canvas.width, canvas.height).data
    canvas2 = document.querySelector('#snowFlakes')
    ctx2 = canvas2.getContext('2d')
    canvas2.width = canvas.offsetWidth
    canvas2.height = canvas.offsetWidth
    initSnow()
  }
  img.crossOrigin = 'Anonymous'
  img.src = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/127738/town.svg?v2'

  var flakes

  function initSnow() {
    ctx2.fillStyle = 'rgba(255,255,255,0.7)'
    var radius = canvas.width * 0.2875
    var offsetX = canvas.width / 2
    var offsetY = canvas.width * 0.4
    flakes = []
    for (var i = 0; i < amount; i++) {
      var x = Math.random() * 2 * radius - radius
      var ylim = Math.sqrt(radius * radius - x * x)
      var y = Math.random() * ylim - ylim
      flakes.push(new Flake(x + offsetX, y + offsetY))
    }
    if (rafAnim === null) {
      rafAnim = requestAnimationFrame(render)
    }
  }

  function Flake(x, y) {
    var destination
    this.x = Math.floor(x)
    this.y = Math.floor(y)
    for (var i = this.y; i < canvas.width; i++) {
      if (data[(this.x + canvas.width * i) * 4 + 3] > 10) {
        destination = parseInt(i - 1)
        i = canvas.width
      } else {
        destination = canvas.width
      }
    }
    this.finalY = destination
    this.r = Math.random() * 2
    this.speedY = Math.random() + 0.2
  }
  Flake.prototype.render = function() {
    if (this.finalY > this.y) {
      this.y += this.speedY
    }
    ctx2.beginPath()
    ctx2.arc(this.x, this.y, this.r, Math.PI * 2, false)
    ctx2.fill()
  }

  function render() {
    requestAnimationFrame(render)
    ctx2.clearRect(0, 0, canvas2.width, canvas2.height)
    for (var i = 0; i < amount; i++) {
      flakes[i].render()
    }
  }

  function shakeGlobe() {
    var globe = document.querySelector('#globe')
    gsap.to(canvas2, 0.5, {
      opacity: 0,
    })
    gsap.to(globe, 0.1, {
      rotationZ: 25,
      ease: 'power4.inOut',
      yoyo: true,
      repeat: 5,
      onComplete: initSnow,
    })
    gsap.to(canvas2, 0.5, {
      opacity: 1,
      delay: '0.6',
    })
  }

  return shakeGlobe
}

const Globe = {
  components: {
    EggAnnouncement,
  },
  data() {
    return { announce: false }
  },
  mounted() {
    const canvas = document.querySelector('#town')
    this.shaker = makeGlobe(canvas)
  },
  methods: {
    shakeGlobe() {
      this.shaker()
      setTimeout(() => {
        this.announce = !isFound('globe')
        foundEgg('globe')
      }, 3000)
    },
  },
}

export default Globe
</script>

<style scoped>
.box {
  background-color: #73b4ab;
  text-align: center;
}
#globe {
  position: relative;
  width: 70vmin;
  height: 70vmin;
  cursor: pointer;
}

#globe:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/127738/globe.svg)
    center center / contain no-repeat;
  z-index: 10;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
