import { createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeft,
  faHandPointRight,
  faUndoAlt,
  faStar,
  faVolumeUp,
  faVolumeMute,
  faTree,
  faSkull,
  faHandPointUp,
  faGifts,
  faBook,
  faGlobe,
  faEgg,
  faHome,
  faGripLines,
  faGlasses,
  faTimesCircle,
  faBullhorn,
  faCommentSlash,
  faLink,
  faWind,
} from '@fortawesome/free-solid-svg-icons'

import './styles/main.scss'
import { initialise } from './shared/store'
import router from './router'
import App from './App.vue'

library.add(
  faArrowLeft,
  faHandPointRight,
  faUndoAlt,
  faStar,
  faVolumeUp,
  faVolumeMute,
  faTree,
  faSkull,
  faHandPointUp,
  faGifts,
  faBook,
  faGlobe,
  faEgg,
  faHome,
  faGripLines,
  faGlasses,
  faTimesCircle,
  faBullhorn,
  faCommentSlash,
  faLink,
  faWind
)

initialise()

createApp(App)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
