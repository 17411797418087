<template>
  <header class="ml-2 mr-2 mt-1">
    <nav
      role="navigation"
      aria-label="main navigation"
      class="is-flex is-justify-content-space-between is-align-items-center"
    >
      <router-link to="/">
        <img
          alt="Bingo Bango logo"
          src="../assets/logo-words.svg"
          style="height: 3rem"
        />
      </router-link>
      <router-link to="/">
        <span class="icon"><font-awesome-icon icon="arrow-left"/></span>
      </router-link>
    </nav>
  </header>

  <section class="section">
    <div class="container">
      <h2 class="title is-4 has-text-centered">Playing game: {{ id }}</h2>
      <div class="is-flex is-justify-content-center is-align-items-center">
        <div class="m-2 pt-1 history-box">
          <History :history="history" />
        </div>
        <div>
          <button
            class="button is-outlined is-medium is-danger current-number"
            @click="handleRecallCurrentNumber"
          >
            <span v-if="currentNumber">
              {{ currentNumber.value }}
            </span>
            <span v-else>
              -
            </span>
          </button>
        </div>
        <div class="ml-2 undo-box"></div>
      </div>

      <div class="mt-1 mb-1">
        <button class="button is-ghost ml-2" @click="mute(false)" v-if="muted">
          <span class="icon has-text-info">
            <font-awesome-icon icon="volume-mute" />
          </span>
        </button>
        <button
          class="button voice is-ghost ml-2 animate__heartBeat animate__infinite"
          @click="mute(true)"
          v-else
          :class="{ animate__animated: animateSpeach }"
        >
          <span class="icon has-text-info">
            <font-awesome-icon icon="volume-up" />
          </span>
        </button>

        <div class="is-inline-block mt-2">{{ currentSaying }}</div>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="number-sheet buttons is-centered">
      <Number
        v-for="number in numbers"
        :key="number.value"
        :number="number"
        :fall="allCalled"
      ></Number>
    </div>
  </div>
  <section class="section">
    <div class="container">
      <div class="select is-info mt-5">
        <select v-model="selectedVoice" @change="handleVoiceChange">
          <option v-for="voice in voices" :value="voice.lang" :key="voice.lang">
            {{ voice.name }}
          </option>
        </select>
      </div>
    </div>
  </section>
</template>

<script>
import Number from './Number'
import History from './History'

import { load, save } from './game'
import {
  voices,
  announceNumber,
  speak,
  currentVoice,
  setVoice,
  muted,
  mute,
} from './announcer'

import Peer from 'peerjs'

const Game = {
  components: {
    Number,
    History,
  },
  props: { id: String },
  data() {
    return {
      numbers: [],
      voices: [],
      selectedVoice: '',
      currentSaying: '',
      muted: false,
      animateSpeach: false,
    }
  },
  computed: {
    history() {
      return this.numbers
        .filter(number => number.calledAt)
        .sort((a, b) => a.calledAt - b.calledAt)
    },
    currentNumber() {
      const lastNumbers = this.history
      return lastNumbers[lastNumbers.length - 1]
    },
  },
  watch: {
    numbers: {
      deep: true,
      handler(numbers) {
        save(this.id, numbers)
      },
    },
  },
  mounted() {
    const setVoices = () => {
      this.voices = voices()
      const theVoice = currentVoice()
      if (theVoice) this.selectedVoice = theVoice.lang
    }
    setVoices()
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = setVoices
    }
    this.numbers = load(this.id)
    this.muted = muted()

    this.speak('Eyes down', this.selectedVoice)

    try {
      this.peer = new Peer()
      this.peer.on('open', () => {
        let conn = this.peer.connect('bingo-bango-' + this.id)
        conn.on('data', data => {
          console.log(`received: ${data}`)
          this.handleNumberCalled(data)
        })
      })
      this.peer.on('error', error => {
        console.error(error)
      })
    } catch (error) {
      console.log('No multi-play', error)
    }
  },
  unmmounted() {
    if (this.peer) this.peer.destroy()
  },
  methods: {
    animateSpeech() {
      this.animateSpeach = true
      setTimeout(() => (this.animateSpeach = false), 2000)
    },
    speak(phrase) {
      this.animateSpeech()
      speak(phrase)
    },
    announce(number, voice) {
      this.animateSpeech()
      return announceNumber(number, voice)
    },
    handleNumberCalled(number) {
      const saying = this.announce(number, this.selectedVoice)
      if (this.numbers[number - 1].calledAt) {
        if (this.currentNumber.value === number) {
          this.currentSaying = saying
        }
      } else {
        this.numbers[number - 1].calledAt = Date.now()
        this.currentSaying = saying
      }
    },
    handleVoiceChange(event) {
      setVoice(this.selectedVoice)
      this.speak(
        `Hi, I'm ${event.target.selectedOptions[0].innerText}. Nice to meet you, stinkers`,
        this.selectedVoice
      )
    },
    handleRecallCurrentNumber() {
      if (this.currentNumber) {
        this.currentSaying = this.announce(
          this.currentNumber.value,
          this.selectedVoice
        )
      }
    },
    handleUndo() {
      if (this.currentNumber) {
        this.speak(`Oops. Nope. Not ${this.currentNumber.value}.`)
        delete this.numbers[this.currentNumber.value - 1].calledAt
        this.currentSaying = ''
      }
    },
    mute(on) {
      on ? this.speak("OK. I'll shut up then.") : this.speak('Hai.')
      this.muted = on
      mute(on)
    },
  },
}

export default Game
</script>

<style scoped>
.history-box {
  text-align: right;
  flex-basis: 50%;
}

.undo-box {
  flex-basis: 50%;
}

.current-number {
  width: 8rem;
}

.current-number:focus {
  background-color: transparent !important;
}

.voice {
  background-color: transparent;
}

.section {
  padding: 1rem 1.5rem;
}

.is-ghost {
  border-color: transparent;
}

.glide__bullets {
  bottom: -1rem;
}
</style>
