<template>
  <div class="xmasTree" :class="treeClass">
    <font-awesome-icon class="star" icon="star" @click="handleTreeClick" />
  </div>

  <EggAnnouncement v-if="announce" @enough="announce = false" />
</template>

<script>
import EggAnnouncement from '../shared/EggAnnouncement'

import { foundEgg, isFound } from '../shared/eggs'

const Tree = {
  components: {
    EggAnnouncement,
  },
  data() {
    return {
      count: 0,
      treeClass: '',
      announce: false,
    }
  },
  methods: {
    handleTreeClick() {
      this.count = this.count + 1
      if (this.count == 2) {
        setTimeout(() => {
          this.announce = !isFound('tree')
          foundEgg('tree')
        }, 1500)
      }
      if (this.count > 2) this.count = 0
      this.treeClass = 'xmasTree--' + this.count
    },
  },
}

export default Tree
</script>

<style lang="scss" scoped>
$gray: #4b4b4b;
$yellow: #f5cd2d;
$blue: #96cbcf;
$darkerYellow: #d8ba00;
$red: #eb0000;
$darkerRed: #990000;
$green: #649632;
$darkerGreen: #2d8434;
$tan: #aa782c;

$pink: pink;
$orange: orange;
$purple: purple;
$darkerBlue: navy;

@mixin animate(
  $name,
  $time: 1s,
  $algor: linear,
  $delay: '',
  $loop: infinite,
  $fill: ''
) {
  -webkit-animation: #{$name} $time $algor #{$delay} $loop #{$fill};
  -moz-animation: #{$name} $time $algor #{$delay} $loop #{$fill};
  -ms-animation: #{$name} $time $algor #{$delay} $loop #{$fill};
  animation: #{$name} $time $algor #{$delay} $loop #{$fill};
}

@mixin icon($size) {
  position: absolute;
  display: block;
  font-size: $size;
  line-height: $size;
  height: $size;
  width: $size;
}

@mixin tree-frame(
  $border: $gray,
  $tree: $green,
  $darkerTree: $darkerGreen,
  $light1: $red,
  $light2: $blue,
  $light3: $darkerYellow
) {
  box-shadow: 0 3em 0 0 $border, 1em 4em 0 0 $border, 0 4em 0 0 $tree,
    -1em 4em 0 0 $border, -2em 5em 0 0 $border, -1em 5em 0 0 $light2,
    0 5em 0 0 $tree, 1em 5em 0 0 $tree, 2em 5em 0 0 $border, 3em 6em 0 0 $border,
    2em 6em 0 0 $tree, 1em 6em 0 0 $light1, 0 6em 0 0 $tree, -1em 6em 0 0 $tree,
    -2em 6em 0 0 $tree, -3em 6em 0 0 $border, -2em 7em 0 0 $border,
    -1em 7em 0 0 $tree, 0 7em 0 0 $darkerTree, 1em 7em 0 0 $tree,
    2em 7em 0 0 $border, 3em 8em 0 0 $border, 2em 8em 0 0 $tree,
    1em 8em 0 0 $darkerTree, 0 8em 0 0 $tree, -1em 8em 0 0 $darkerTree,
    -2em 8em 0 0 $tree, -3em 8em 0 0 $border, -4em 9em 0 0 $border,
    -3em 9em 0 0 $tree, -2em 9em 0 0 $light1, -1em 9em 0 0 $tree,
    0 9em 0 0 $light3, 1em 9em 0 0 $tree, 2em 9em 0 0 $light2, 3em 9em 0 0 $tree,
    4em 9em 0 0 $border, 3em 10em 0 0 $border, 2em 10em 0 0 $tree,
    1em 10em 0 0 $darkerTree, 0 10em 0 0 $tree, -1em 10em 0 0 $darkerTree,
    -2em 10em 0 0 $tree, -3em 10em 0 0 $border, -4em 11em 0 0 $border,
    -3em 11em 0 0 $tree, -2em 11em 0 0 $darkerTree, -1em 11em 0 0 $tree,
    0 11em 0 0 $darkerTree, 1em 11em 0 0 $tree, 2em 11em 0 0 $darkerTree,
    3em 11em 0 0 $light3, 4em 11em 0 0 $border, 5em 12em 0 0 $border,
    4em 12em 0 0 $tree, 3em 12em 0 0 $tree, 2em 12em 0 0 $tree,
    1em 12em 0 0 $tree, 0 12em 0 0 $tree, -1em 12em 0 0 $tree,
    -2em 12em 0 0 $tree, -3em 12em 0 0 $tree, -4em 12em 0 0 $tree,
    -5em 12em 0 0 $border, -4em 13em 0 0 $border, -3em 13em 0 0 $light3,
    -2em 13em 0 0 $tree, -1em 13em 0 0 $light2, 0 13em 0 0 $tree,
    1em 13em 0 0 $tree, 2em 13em 0 0 $light1, 3em 13em 0 0 $tree,
    4em 13em 0 0 $border, 3em 14em 0 0 $border, 2em 14em 0 0 $border,
    1em 14em 0 0 $border, 0 14em 0 0 $tan, -1em 14em 0 0 $border,
    -2em 14em 0 0 $border, -3em 14em 0 0 $border, -1em 15em 0 0 $border,
    0 15em 0 0 $border, 1em 15em 0 0 $border;
}

@keyframes flash {
  10% {
    /* opacity: 0.5; */
    transform: scale(0.5, 0.5);
  }
}

.star {
  cursor: pointer;
  color: $yellow;
  @include icon(60px);
  top: 10px;
  left: -20px;
  @include animate($name: flash, $algor: ease, $fill: forwards);
}

.xmasTree {
  @include animate($name: xmasTree, $fill: forwards);

  width: 1em;
  height: 1em;
  position: relative;
  left: 50%;
  @include tree-frame();
}

.xmasTree--1 {
  @include animate($name: xmasTree--1, $fill: forwards);

  @include tree-frame(
    $border: $pink,
    $tree: $red,
    $darkerTree: $darkerRed,
    $light1: $orange,
    $light2: $blue,
    $light3: $darkerYellow
  );
}

.xmasTree--2 {
  @include animate($name: xmasTree--2, $fill: forwards);

  @include tree-frame(
    $border: $orange,
    $tree: $purple,
    $darkerTree: $darkerBlue,
    $light1: $green,
    $light2: $darkerYellow,
    $light3: $pink
  );
}

@keyframes xmasTree--2 {
  25% {
    @include tree-frame(
      $border: $orange,
      $tree: $purple,
      $darkerTree: $darkerBlue,
      $light1: $purple,
      $light2: $darkerYellow,
      $light3: $pink
    );
  }

  50% {
    @include tree-frame(
      $border: $orange,
      $tree: $purple,
      $darkerTree: $darkerBlue,
      $light1: $green,
      $light2: $purple,
      $light3: $pink
    );
  }
  75% {
    @include tree-frame(
      $border: $orange,
      $tree: $purple,
      $darkerTree: $darkerBlue,
      $light1: $green,
      $light2: $darkerYellow,
      $light3: $purple
    );
  }
  100% {
    @include tree-frame(
      $border: $orange,
      $tree: $purple,
      $darkerTree: $darkerBlue,
      $light1: $green,
      $light2: $darkerYellow,
      $light3: $pink
    );
  }
}

@keyframes xmasTree--1 {
  25% {
    @include tree-frame(
      $border: $pink,
      $tree: $red,
      $darkerTree: $darkerRed,
      $light1: $red,
      $light2: $blue,
      $light3: $darkerYellow
    );
  }

  50% {
    @include tree-frame(
      $border: $pink,
      $tree: $red,
      $darkerTree: $darkerRed,
      $light1: $orange,
      $light2: $red,
      $light3: $darkerYellow
    );
  }
  75% {
    @include tree-frame(
      $border: $pink,
      $tree: $red,
      $darkerTree: $darkerRed,
      $light1: $orange,
      $light2: $blue,
      $light3: $red
    );
  }
  100% {
    @include tree-frame(
      $border: $pink,
      $tree: $red,
      $darkerTree: $darkerRed,
      $light1: $orange,
      $light2: $blue,
      $light3: $darkerYellow
    );
  }
}

@keyframes xmasTree {
  25% {
    box-shadow: 0 3em 0 0 $gray, 1em 4em 0 0 $gray, 0 4em 0 0 $green,
      -1em 4em 0 0 $gray, -2em 5em 0 0 $gray, -1em 5em 0 0 $blue,
      0 5em 0 0 $green, 1em 5em 0 0 $green, 2em 5em 0 0 $gray, 3em 6em 0 0 $gray,
      2em 6em 0 0 $green, 1em 6em 0 0 $green, 0 6em 0 0 $green,
      -1em 6em 0 0 $green, -2em 6em 0 0 $green, -3em 6em 0 0 $gray,
      -2em 7em 0 0 $gray, -1em 7em 0 0 $green, 0 7em 0 0 $darkerGreen,
      1em 7em 0 0 $green, 2em 7em 0 0 $gray, 3em 8em 0 0 $gray,
      2em 8em 0 0 $green, 1em 8em 0 0 $darkerGreen, 0 8em 0 0 $green,
      -1em 8em 0 0 $darkerGreen, -2em 8em 0 0 $green, -3em 8em 0 0 $gray,
      -4em 9em 0 0 $gray, -3em 9em 0 0 $green, -2em 9em 0 0 $green,
      -1em 9em 0 0 $green, 0 9em 0 0 $darkerYellow, 1em 9em 0 0 $green,
      2em 9em 0 0 $blue, 3em 9em 0 0 $green, 4em 9em 0 0 $gray,
      3em 10em 0 0 $gray, 2em 10em 0 0 $green, 1em 10em 0 0 $darkerGreen,
      0 10em 0 0 $green, -1em 10em 0 0 $darkerGreen, -2em 10em 0 0 $green,
      -3em 10em 0 0 $gray, -4em 11em 0 0 $gray, -3em 11em 0 0 $green,
      -2em 11em 0 0 $darkerGreen, -1em 11em 0 0 $green, 0 11em 0 0 $darkerGreen,
      1em 11em 0 0 $green, 2em 11em 0 0 $darkerGreen, 3em 11em 0 0 $darkerYellow,
      4em 11em 0 0 $gray, 5em 12em 0 0 $gray, 4em 12em 0 0 $green,
      3em 12em 0 0 $green, 2em 12em 0 0 $green, 1em 12em 0 0 $green,
      0 12em 0 0 $green, -1em 12em 0 0 $green, -2em 12em 0 0 $green,
      -3em 12em 0 0 $green, -4em 12em 0 0 $green, -5em 12em 0 0 $gray,
      -4em 13em 0 0 $gray, -3em 13em 0 0 $darkerYellow, -2em 13em 0 0 $green,
      -1em 13em 0 0 $blue, 0 13em 0 0 $green, 1em 13em 0 0 $green,
      2em 13em 0 0 $green, 3em 13em 0 0 $green, 4em 13em 0 0 $gray,
      3em 14em 0 0 $gray, 2em 14em 0 0 $gray, 1em 14em 0 0 $gray,
      0 14em 0 0 $tan, -1em 14em 0 0 $gray, -2em 14em 0 0 $gray,
      -3em 14em 0 0 $gray, -1em 15em 0 0 $gray, 0 15em 0 0 $gray,
      1em 15em 0 0 $gray;
  }

  50% {
    box-shadow: 0 3em 0 0 $gray, 1em 4em 0 0 $gray, 0 4em 0 0 $green,
      -1em 4em 0 0 $gray, -2em 5em 0 0 $gray, -1em 5em 0 0 $green,
      0 5em 0 0 $green, 1em 5em 0 0 $green, 2em 5em 0 0 $gray, 3em 6em 0 0 $gray,
      2em 6em 0 0 $green, 1em 6em 0 0 $red, 0 6em 0 0 $green,
      -1em 6em 0 0 $green, -2em 6em 0 0 $green, -3em 6em 0 0 $gray,
      -2em 7em 0 0 $gray, -1em 7em 0 0 $green, 0 7em 0 0 $darkerGreen,
      1em 7em 0 0 $green, 2em 7em 0 0 $gray, 3em 8em 0 0 $gray,
      2em 8em 0 0 $green, 1em 8em 0 0 $darkerGreen, 0 8em 0 0 $green,
      -1em 8em 0 0 $darkerGreen, -2em 8em 0 0 $green, -3em 8em 0 0 $gray,
      -4em 9em 0 0 $gray, -3em 9em 0 0 $green, -2em 9em 0 0 $red,
      -1em 9em 0 0 $green, 0 9em 0 0 $darkerYellow, 1em 9em 0 0 $green,
      2em 9em 0 0 $green, 3em 9em 0 0 $green, 4em 9em 0 0 $gray,
      3em 10em 0 0 $gray, 2em 10em 0 0 $green, 1em 10em 0 0 $darkerGreen,
      0 10em 0 0 $green, -1em 10em 0 0 $darkerGreen, -2em 10em 0 0 $green,
      -3em 10em 0 0 $gray, -4em 11em 0 0 $gray, -3em 11em 0 0 $green,
      -2em 11em 0 0 $darkerGreen, -1em 11em 0 0 $green, 0 11em 0 0 $darkerGreen,
      1em 11em 0 0 $green, 2em 11em 0 0 $darkerGreen, 3em 11em 0 0 $darkerYellow,
      4em 11em 0 0 $gray, 5em 12em 0 0 $gray, 4em 12em 0 0 $green,
      3em 12em 0 0 $green, 2em 12em 0 0 $green, 1em 12em 0 0 $green,
      0 12em 0 0 $green, -1em 12em 0 0 $green, -2em 12em 0 0 $green,
      -3em 12em 0 0 $green, -4em 12em 0 0 $green, -5em 12em 0 0 $gray,
      -4em 13em 0 0 $gray, -3em 13em 0 0 $darkerYellow, -2em 13em 0 0 $green,
      -1em 13em 0 0 $green, 0 13em 0 0 $green, 1em 13em 0 0 $green,
      2em 13em 0 0 $red, 3em 13em 0 0 $green, 4em 13em 0 0 $gray,
      3em 14em 0 0 $gray, 2em 14em 0 0 $gray, 1em 14em 0 0 $gray,
      0 14em 0 0 $tan, -1em 14em 0 0 $gray, -2em 14em 0 0 $gray,
      -3em 14em 0 0 $gray, -1em 15em 0 0 $gray, 0 15em 0 0 $gray,
      1em 15em 0 0 $gray;
  }
  75% {
    box-shadow: 0 3em 0 0 $gray, 1em 4em 0 0 $gray, 0 4em 0 0 $green,
      -1em 4em 0 0 $gray, -2em 5em 0 0 $gray, -1em 5em 0 0 $blue,
      0 5em 0 0 $green, 1em 5em 0 0 $green, 2em 5em 0 0 $gray, 3em 6em 0 0 $gray,
      2em 6em 0 0 $green, 1em 6em 0 0 $red, 0 6em 0 0 $green,
      -1em 6em 0 0 $green, -2em 6em 0 0 $green, -3em 6em 0 0 $gray,
      -2em 7em 0 0 $gray, -1em 7em 0 0 $green, 0 7em 0 0 $darkerGreen,
      1em 7em 0 0 $green, 2em 7em 0 0 $gray, 3em 8em 0 0 $gray,
      2em 8em 0 0 $green, 1em 8em 0 0 $darkerGreen, 0 8em 0 0 $green,
      -1em 8em 0 0 $darkerGreen, -2em 8em 0 0 $green, -3em 8em 0 0 $gray,
      -4em 9em 0 0 $gray, -3em 9em 0 0 $green, -2em 9em 0 0 $red,
      -1em 9em 0 0 $green, 0 9em 0 0 $green, 1em 9em 0 0 $green,
      2em 9em 0 0 $blue, 3em 9em 0 0 $green, 4em 9em 0 0 $gray,
      3em 10em 0 0 $gray, 2em 10em 0 0 $green, 1em 10em 0 0 $darkerGreen,
      0 10em 0 0 $green, -1em 10em 0 0 $darkerGreen, -2em 10em 0 0 $green,
      -3em 10em 0 0 $gray, -4em 11em 0 0 $gray, -3em 11em 0 0 $green,
      -2em 11em 0 0 $darkerGreen, -1em 11em 0 0 $green, 0 11em 0 0 $darkerGreen,
      1em 11em 0 0 $green, 2em 11em 0 0 $darkerGreen, 3em 11em 0 0 $green,
      4em 11em 0 0 $gray, 5em 12em 0 0 $gray, 4em 12em 0 0 $green,
      3em 12em 0 0 $green, 2em 12em 0 0 $green, 1em 12em 0 0 $green,
      0 12em 0 0 $green, -1em 12em 0 0 $green, -2em 12em 0 0 $green,
      -3em 12em 0 0 $green, -4em 12em 0 0 $green, -5em 12em 0 0 $gray,
      -4em 13em 0 0 $gray, -3em 13em 0 0 $green, -2em 13em 0 0 $green,
      -1em 13em 0 0 $blue, 0 13em 0 0 $green, 1em 13em 0 0 $green,
      2em 13em 0 0 $red, 3em 13em 0 0 $green, 4em 13em 0 0 $gray,
      3em 14em 0 0 $gray, 2em 14em 0 0 $gray, 1em 14em 0 0 $gray,
      0 14em 0 0 $tan, -1em 14em 0 0 $gray, -2em 14em 0 0 $gray,
      -3em 14em 0 0 $gray, -1em 15em 0 0 $gray, 0 15em 0 0 $gray,
      1em 15em 0 0 $gray;
  }
  100% {
    box-shadow: 0 3em 0 0 $gray, 1em 4em 0 0 $gray, 0 4em 0 0 $green,
      -1em 4em 0 0 $gray, -2em 5em 0 0 $gray, -1em 5em 0 0 $blue,
      0 5em 0 0 $green, 1em 5em 0 0 $green, 2em 5em 0 0 $gray, 3em 6em 0 0 $gray,
      2em 6em 0 0 $green, 1em 6em 0 0 $red, 0 6em 0 0 $green,
      -1em 6em 0 0 $green, -2em 6em 0 0 $green, -3em 6em 0 0 $gray,
      -2em 7em 0 0 $gray, -1em 7em 0 0 $green, 0 7em 0 0 $darkerGreen,
      1em 7em 0 0 $green, 2em 7em 0 0 $gray, 3em 8em 0 0 $gray,
      2em 8em 0 0 $green, 1em 8em 0 0 $darkerGreen, 0 8em 0 0 $green,
      -1em 8em 0 0 $darkerGreen, -2em 8em 0 0 $green, -3em 8em 0 0 $gray,
      -4em 9em 0 0 $gray, -3em 9em 0 0 $green, -2em 9em 0 0 $red,
      -1em 9em 0 0 $green, 0 9em 0 0 $darkerYellow, 1em 9em 0 0 $green,
      2em 9em 0 0 $blue, 3em 9em 0 0 $green, 4em 9em 0 0 $gray,
      3em 10em 0 0 $gray, 2em 10em 0 0 $green, 1em 10em 0 0 $darkerGreen,
      0 10em 0 0 $green, -1em 10em 0 0 $darkerGreen, -2em 10em 0 0 $green,
      -3em 10em 0 0 $gray, -4em 11em 0 0 $gray, -3em 11em 0 0 $green,
      -2em 11em 0 0 $darkerGreen, -1em 11em 0 0 $green, 0 11em 0 0 $darkerGreen,
      1em 11em 0 0 $green, 2em 11em 0 0 $darkerGreen, 3em 11em 0 0 $darkerYellow,
      4em 11em 0 0 $gray, 5em 12em 0 0 $gray, 4em 12em 0 0 $green,
      3em 12em 0 0 $green, 2em 12em 0 0 $green, 1em 12em 0 0 $green,
      0 12em 0 0 $green, -1em 12em 0 0 $green, -2em 12em 0 0 $green,
      -3em 12em 0 0 $green, -4em 12em 0 0 $green, -5em 12em 0 0 $gray,
      -4em 13em 0 0 $gray, -3em 13em 0 0 $darkerYellow, -2em 13em 0 0 $green,
      -1em 13em 0 0 $blue, 0 13em 0 0 $green, 1em 13em 0 0 $green,
      2em 13em 0 0 $red, 3em 13em 0 0 $green, 4em 13em 0 0 $gray,
      3em 14em 0 0 $gray, 2em 14em 0 0 $gray, 1em 14em 0 0 $gray,
      0 14em 0 0 $tan, -1em 14em 0 0 $gray, -2em 14em 0 0 $gray,
      -3em 14em 0 0 $gray, -1em 15em 0 0 $gray, 0 15em 0 0 $gray,
      1em 15em 0 0 $gray;
  }
}
</style>
