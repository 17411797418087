import { appState } from './store'

export const getEggs = () => {
  return appState.eggs
}

export const isFound = id => {
  return appState.eggs[id]
}

export const foundEgg = id => {
  appState.eggs[id] = true
}
