<template>
  <teleport to="body">
    <div class="modal" @click="handleClose">
      <div>
        <h1 class="title is-3 has-text-centered">
          Eggcellent!! <br />
          You've found an Egg!
        </h1>
        <h2 class="subtitle is-4">Let's Shellebrate</h2>

        <h1 class="title is-1 mt-2 mtb-2">
          <font-awesome-icon
            id="egg"
            icon="egg"
            size="3x"
            class="animate__animated animate__infinite animate__rubberBand"
          />
        </h1>

        <button class="button is-success" @click="handleClose">
          Un oeuf
        </button>
      </div>
    </div>
  </teleport>
</template>

<script>
import party from './party'

export default {
  emits: ['enough'],
  methods: {
    handleClose() {
      this.$emit('enough')
    },
  },
  mounted() {
    party.screen({ count: 800 })

    this.partyInterval = setInterval(() => {
      party.element(document.getElementById('egg'))
    }, 1000)
  },
  unmounted() {
    clearInterval(this.partyInterval)
  },
}
</script>

<style>
#party-js-canvas {
  z-index: 1001;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal div {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vmin;
  height: 90vmin;
  padding: 5px;
}
</style>
