<template>
  <button
    @click="callNumber"
    class="button is-outlined is-info"
    :class="[animation ? 'animate__animated' : '', animation]"
  >
    0
  </button>
</template>

<script>
const FakeNumber = {
  data() {
    return { animation: null }
  },
  methods: {
    callNumber() {
      this.animation = 'animate__swing animate__infinite'

      setTimeout(() => (this.animation = 'animate__hinge'), 2000)
      setTimeout(() => this.$router.push({ name: 'Santa' }), 5000)
    },
  },
}

export default FakeNumber
</script>

<style scoped>
button {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  margin: 0.2rem;
  z-index: 10001;
  position: relative;
}
</style>
