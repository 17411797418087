import { getSaying } from './sayings'
import { appState } from '../shared/store'

export const voices = () => {
  try {
    //  TODO: sort, there's load on Android
    return speechSynthesis.getVoices()
  } catch (error) {
    console.log('Speachless', error)
    return []
  }
}

export const muted = () => appState.settings.muted

export const mute = on => {
  appState.settings.muted = on
}

export const setVoice = lang => {
  appState.settings.currentVoice = lang
}

export const currentVoice = () => {
  try {
    const currentVoice = appState.settings.currentVoice
    const voices = speechSynthesis.getVoices()
    let voice = null
    if (currentVoice) {
      voice = voices.find(v => v.lang === currentVoice)
    } else {
      voice = voices.find(v => v.default)
    }

    if (voice) {
      return voice
    } else {
      return voices.length > 0 ? voices[0] : null
    }
  } catch (error) {
    console.log('Have no voice', error)
    return null
  }
}

export const announceNumber = (number, language) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API/Using_the_Web_Speech_API#Speech_synthesis
  //  TODO: space out the saying vs number, and change emphasis or spead of each
  const saying = getSaying(number)
  speak(saying + '. number ' + number, language)
  return saying
}

export const speak = (phrase, language) => {
  console.log('say:', phrase)
  try {
    const utterThis = new SpeechSynthesisUtterance(phrase)
    utterThis.voice = getVoice(language)
    if (!muted()) window.speechSynthesis.speak(utterThis)
  } catch (error) {
    console.log('I cannot say :(', error)
  }
}

const getVoice = language => {
  try {
    const voices = speechSynthesis.getVoices()
    const voice = voices.find(v => v.lang == language)
    if (voice) {
      return voice
    } else {
      return currentVoice()
    }
  } catch (error) {
    console.log('I have no voice', error)
    return null
  }
}
