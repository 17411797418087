// TODO: load up sayings

const sayings = [
  "Kelly's Eye",
  'One Little Duck',
  'Cup of Tea',
  'Knock at the Door',
  'Man Alive',
  ['Half a Dozen', 'Tom Mix', 'Little Mix'],
  ['Lucky Seven', 'Lucky for some'],
  'Garden Gate',
  ["Doctor's Order", 'Brighton line'],
  "Bojo's Den",
  // 10
  'Legs Eleven',
  'One Dozen',
  'Unlucky for Some',
  'Valentine’s Day',
  'Young and Keen',
  ['Sweet 16', 'Never been kised'],
  ['Dancing Queen', 'Selfie Queen'],
  'Coming of Age',
  'Goodbye Teens',
  'One Score',
  //  20
  ['Key of the Door', 'Royal salute'],
  'Two Little Ducks',
  ['Thee and Me', 'The Lord is My Shepherd'],
  'Two Dozen',
  'Duck and Dive',
  ['Pick N Mix', 'Half a crown'],
  ['Gateway to Heaven', 'Duck and a crutch'],
  ['Overweight', 'In a state'],
  'Rise and Shine',
  'Dirty Gertie',
  //  30
  'Get up and Run',
  'Buckle my Shoe',
  ['Dirty Knee', 'All the Threes', 'Fish, Chips and Peas'],
  'Ask for More',
  'Jump and Jive',
  'Three Dozen',
  'More than Eleven',
  'Christmas Cake',
  'Steps',
  ['Naughty 40', 'Life begins'],
  // 40
  'Time for Fun',
  'Winnie the Pooh',
  'Down on your Knees',
  'Droopy Drawers',
  'Halfway There',
  'Up to Tricks',
  'Four and Seven',
  ['Four Dozen', 'Tag a Mate'],
  'PC',
  ['Half a Century', "It's a bullseye!", "5-o 5-o, it's off to work we go"],
  // 50
  'Tweak of the Thumb',
  ['Danny La Rue', 'Chicken vindaloo', 'Deck of cards'],
  ['Stuck in the Tree', 'Here comes Herbie!'],
  ['Clean the Floor', 'Man at the door'],
  ['Snakes Alive', 'All the fives'],
  'Was she worth it?',
  'Heinz Varieties',
  'Make them Wait',
  'Brighton Line',
  ['Grandma’s Getting Frisky', 'Five dozen'],
  // 60
  'Bakers Bun',
  ['Turn on the Screw', 'Tickety-boo'],
  'Tickle Me',
  ['Red Raw', 'Almost retired'],
  'Old Age Pension',
  'Clickety Click',
  ['Made in Heaven', 'Stairway to Heaven'],
  ['Saving Grace', 'Pick a mate'],
  ['Either way up', 'Meal for two', 'Anyway up', 'A favourite of mine'],
  'Three Score and 10',
  // 70
  ['Bang on the Drum', "J.Lo's bum"],
  ['Six Dozen', 'Danny La Rue'],
  ['Queen Bee', 'Under the tree', 'Lucky 3'],
  ['Candy Store', 'Hit the floor'],
  'Strive and Strive',
  'Trombones',
  ['Sunset Strip', 'Two little crutches'],
  ['Heaven’s Gate', '39 more steps'],
  'One More Time',
  ['Eight and Blank', "Gandhi's breakfast"],
  // 80
  ['Stop and Run', 'Fat lady with a walking stick'],
  'Straight on Through',
  ['Stop Farting!', 'Time for tea'],
  'Seven Dozen',
  'Staying Alive',
  'Between the Sticks',
  'Torquay in Devon',
  'Two Fat Ladies',
  ['Nearly There', 'Almost There'],
  'Top of the Shop',
  // 90
]

export const getSaying = number => {
  if (number <= sayings.length) {
    const theCall = sayings[number - 1]
    if (typeof theCall === 'string') {
      return theCall
    } else {
      return theCall[Math.floor(Math.random() * theCall.length)]
    }
  } else {
    return ''
  }
}
